<spb-logo title="Byggnadskreditiv"></spb-logo>

@if (configService.logInState$(); as user) {
  <div class="menu-holder">
    <span class="username">{{ user.name }}</span>

    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon>menu</mat-icon>

      @if (canUseTestingTools) {
        <div class="developer-icon-holder">
          <mat-icon class="material-icons-outlined developer-icon">handyman</mat-icon>
        </div>
      }
    </button>
  </div>
}

<!-- Add other fancy navigations here -->
<mat-menu #menu="matMenu">
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>
